.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    transition: background-color 0.3s ease;
    backdrop-filter: blur(10px);
    z-index: 1000;
    box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.5); 
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .navbar.scrolled {
    background-color: rgba(0, 0, 0, 0.9); 
  }
  
  .navbar-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-shadow: 1px 1px 1px rgba(2, 2, 2, 0.59);
    cursor: pointer;
    color: rgb(199, 193, 193); 
    text-align: center;
    height: 50px;
  }
  
  .navbar-content div {
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .navbar-content div:hover {
    background-color: rgba(175, 243, 163, 0.782);
    color: dodgerblue;
  }
  
  .navbar-content div {
    cursor: pointer;
    padding: 0; 
    border-radius: 0;
    overflow: hidden;
    position: relative;
    background: transparent;
    border: none;
  }
  
  .btnshine {
    padding: 8px 20px;
    width: 200px;
    color: #ffffff6e;
    font-weight: 300;
    border-radius: 0;
    text-transform: uppercase;
    margin: 25px;
    overflow: hidden;
    position: relative;
    background: transparent;
    /* border: 2px solid white; */
    animation: flyIn 1.5s ease forwards;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  
   /* .btnshine::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, #000000, transparent);
    transform: translateX(-100%);
    transition: 1.6s;
    position: absolute;
  }
  
   .btnshine:hover::before {
    transform: translateX(100%); 
  }  */
  
  .btnshine:hover {
    box-shadow: 0 0 20px 5px #000000;
    color: #fff;
  }
  
  .btnshine2 {
    padding: 8px 0px;
    width: 200px;
    color: #fff;
    font-weight: 300;
    border-radius: 0;
    text-transform: uppercase;
    margin: 25px;
    overflow: hidden;
    position: relative;
    background: transparent;
    /* border: 2px solid white; */
    animation: flyIn 1.5s ease forwards;
    text-align: center;
  }
  
  .btnshine2::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, #000000, transparent);
    transform: translateX(-100%);
    transition: 1.6s;
    position: absolute;
  }
  
  .btnshine2:hover::before {
    transform: translateX(100%); 
  }
  
  .btnshine2:hover {
    box-shadow: 0 0 20px 5px #000000;
  }
  
  .btnshine3 {
    padding: 8px 0px;
    width: 200px;
    color: #fff;
    font-weight: 300;
    border-radius: 0;
    text-transform: uppercase;
    margin: 25px;
    overflow: hidden;
    position: relative;
    background: transparent;
    /* border: 2px solid white; */
    animation: flyIn 1.5s ease forwards;
    text-align: center;
  }
  
  .btnshine3::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, #000000, transparent);
    transform: translateX(-100%);
    transition: 1.6s;
    position: absolute;
  }
  
  .btnshine3:hover::before {
    transform: translateX(100%); 
  }
  
  .btnshine3:hover {
    box-shadow: 0 0 20px 5px #000000;
  }
  
  .btnshine4 {
    padding: 8px 0px;
    width: 200px;
    color: #fff;
    font-weight: 300;
    border-radius: 0;
    text-transform: uppercase;
    margin: 0px!important;
    overflow: hidden;
    position: relative;
    background: transparent;
    /* border: 2px solid white; */
    animation: flyIn 1.5s ease forwards;
    text-align: center;
  }
  
  .btnshine4::before {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(120deg, transparent, #000000, transparent);
    transform: translateX(-100%);
    transition: 1.6s;
    position: absolute;
  }
  
  .btnshine4:hover::before {
    transform: translateX(100%); 
  }
  
  .btnshine4:hover {
    box-shadow: 0 0 20px 5px #000000;
  }

  @keyframes flyRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .innovation-text {
    font-size: 150px;
    font-family: 'Arial', sans-serif;
    color: white; 
    background-clip: text;
    text-shadow: 2px 2px 2px rgba(10, 10, 10, 0.78);
    text-align: center;
    background-size: cover;
    background-blend-mode: lighten; 
    animation: flyIn 1.5s ease forwards;
    z-index: 1;
  }
  
  @keyframes flyIn {
    from {
      transform: translateY(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .innovation-subtext {
    z-index: 2;
    text-shadow: 2.5px 2.5px 5.5px rgb(0, 0, 0);
    animation: flyLeft 1.5s ease forwards;
    color: #ffffff;
  }
  
  @keyframes flyLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  

.background {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

  .section {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; 
    animation: flyStraightAnimZ 2s ease-in forwards;
    background-image: url('./img/backgroundbig.png');
    background-repeat: no-repeat;
    background-size: cover; /* Hintergrundbild an die Größe des Elements anpassen */
    background-position: center; /* Zentrieren des Hintergrundbildes */
  }
  
  .section:nth-child(even) {
    background-color: #121212; 
    animation: flyStraightAnimZ 2s ease-in forwards;
    background-image: url('./img/backgroundbig.png');
    background-image: url('./img/backgroundbig.png');
    background-repeat: no-repeat;
    background-size: cover; /* Hintergrundbild an die Größe des Elements anpassen */
    background-position: center; /* Zentrieren des Hintergrundbildes */
  }
  
  .section:nth-child(odd) {
    background-color: #ffffff; 
    animation: flyStraightAnimZ 2s ease-in forwards;
  }

  .flyStraight {
    animation: flyStraightAnimZ 2s ease-in forwards;
  }
  
  .flyStraightAnim {
    animation: flyStraightZ 2s ease-in forwards;
  }
  
  @keyframes flyStraightAnim {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes flyStraighZ {
    from {
      transform: translateZ(-100%);
      opacity: 0;
    }
    to {
      transform: translateZ(0);
      opacity: 1;
    }
  }

  @keyframes flyRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
  
    to {
      transform: translateX(0%);
      opacity: 1;
    }
  }


  @keyframes flyRighthart {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
  
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }



    /* .home {
      animation: flyStraightAnim 0.5s ease-in forwards;
    }

    .about {
        animation: flyStraightAnim 0.5s ease-in forwards;
    }

    .contact {
        animation: flyStraightAnim 0.5s ease-in forwards;
    }

    .show {
        animation: flyStraightAnim 0.5s ease-in forwards;
    } */

    .references-list {
      position: relative;
      top: 100px;
      marker: none;
    }



    /* @media only screen and (max-width: 940px) {
/* Burger-Menü-Button */
.navbar-menu-btn {
    display: none; /* Standardmäßig ausgeblendet */
  }
  
  /* Burger-Menü-Button-Linien */
  .burger-line {
    width: 100%;
    height: 2px; /* Höhe der Linien */
    background-color: white;
  }
  
  /* Burger-Menü-Liste */
  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
  }
  
  /* Styling für die Links im Burger-Menü */
  .navbar-content .btnshine {
    cursor: pointer;
    border-radius: 5px;
    /* transition: background-color 0.3s ease; */
  }
  
  .navbar-content .btnshine:hover {
    background-color: rgba(175, 243, 163, 0.782);
    color: dodgerblue;
  }
  
  .navbar-content .btnshine {
    cursor: pointer;
    padding: 0; 
    border-radius: 0;
    overflow: hidden;
    position: relative;
    background: transparent;
    border: none;
  }
  
  /* Media Query für das Burger-Menü */
  @media only screen and (max-width: 1010px) {
/* Burger-Menü-Button */
.navbar-menu-btn {
    display: none; /* Standardmäßig ausgeblendet */
    position: fixed;
    top: 20px; /* Abstand vom oberen Rand */
    right: 20px; /* Abstand vom rechten Rand */
    cursor: pointer;
  }
  /* Burger-Menü-Button-Linien */
  .burger-line {
    width: 100%;
    height: 2px; /* Höhe der Linien */
    background-color: white;
  }
  
  /* Burger-Menü-Liste */
  .navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    cursor: pointer;
  }
  
  /* Styling für die Links im Burger-Menü */
  .navbar-content .btnshine {
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .navbar-content .btnshine:hover {
    background-color: rgba(175, 243, 163, 0.782);
    color: dodgerblue;
  }
  
  .navbar-content .btnshine {
    cursor: pointer;
    padding: 0; 
    border-radius: 0;
    overflow: hidden;
    position: relative;
    background: transparent;
    border: none;
  }
  
  @media only screen and (max-width: 1010px) {
    /* Burger-Menü-Button anzeigen */
    .navbar-menu-btn {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      width: 55px; /* Breite des Burger-Menü-Buttons */
      height: 40px; /* Höhe des Burger-Menü-Buttons */
      cursor: pointer;
      margin-top: -15px;
      z-index: 100;
    }
  
    /* Burger-Menü-Liste anzeigen */
    .navbar-content {
      display: none; /* Standardmäßig ausgeblendet */
      position: absolute;
      top: 80px; /* Die Höhe der Navbar berücksichtigen */
      left: 0;
      background-color: rgba(240, 240, 240, 0.9); /* Hintergrundfarbe für die Liste */
      width: 100%; /* Die volle Breite des Bildschirms */
      padding: 20px;
      box-sizing: border-box;
    }
  
    /* Burger-Menü-Liste anzeigen, wenn das Burger-Menü geklickt wird */
    .navbar-content.show {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      animation: none;
    }
  
    /* Styling für die Links im Burger-Menü */
    .navbar-content .btnshine {
      margin-bottom: 15px; /* Abstand zwischen den Links */
      color: white; /* Textfarbe */
      text-decoration: none; /* Unterstreichung entfernen */
      font-size: 16px; /* Schriftgröße */
      font-weight: bold; /* Schriftgewicht */
    }
  
    /* Farbe beim Hovern */
    .navbar-content .btnshine:hover {
      color: dodgerblue;
    }
}

.navbar-menu-list {
    display: none; /* Standardmäßig ausgeblendet */
    position: absolute;
    top: 50px; /* Die Höhe der Navbar berücksichtigen */
    right: 0;
    background-color: transparent;
    width: 200px; /* Breite der Dropdown-Liste */
    padding: 20px;
    box-sizing: border-box;
    height: 345px;
    cursor: pointer;
  }
  
  .navbar-menu-list.show {
    display: block; /* Die Liste wird angezeigt, wenn das Burger-Menü geklickt wird */
    margin-right: 20px;
     margin-top: -30px;
}
  
  /* Styling für die Links im Burger-Menü */
  .navbar-menu-list .btnshine {
    display: block;
    margin-bottom: 15px; /* Abstand zwischen den Links */
    color: white; /* Textfarbe */
    text-decoration: none; /* Unterstreichung entfernen */
    font-size: 16px; /* Schriftgröße */
    font-weight: bold; /* Schriftgewicht */
    cursor: pointer;
  }
  
  .navbar-menu-list .btnshine:hover {
    color: rgb(255, 83, 30); /* Farbe beim Hovern */
  }
  
  .btnshine {
    padding: 8px 20px;
    width: 200px;
    color: #ffffff6e;
    font-weight: 300;
    border-radius: 0;
    text-transform: uppercase;
    margin: 25px;
    overflow: hidden;
    position: relative;
    background: transparent;
    animation: flyIn 0.5s ease forwards; /* Verkürzte Animationsdauer */
    text-align: center;
}

.home {
    animation: flyStraightAnim 0.5s ease-in forwards;
  }

  .about {
      animation: flyStraightAnim 0.5s ease-in forwards;
  }

  .contact {
      animation: flyStraightAnim 0.5s ease-in forwards;
  }

  .show {
      animation: flyStraightAnim 0.5s ease-in forwards;
  }


}

