.App {
  text-align: center;
  overflow: hidden;
}

/* .App-logo {
  height: 15vmin;
  pointer-events: none;
  animation: flyIn 1.5s ease forwards;
  margin-bottom: -124px;
} */

.App-logo2 {
  height: 20vmin;
  pointer-events: none;
  animation: flyIn 1.5s ease forwards;
  margin-bottom: 10px;
  z-index: 1;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('./img/backgroundbig.png');
  background-repeat: no-repeat;
  background-size: cover; /* Hintergrundbild an die Größe des Elements anpassen */
  background-position: center; /* Zentrieren des Hintergrundbildes */
}

/* Wenn das Anzeigefenster kleiner wird */
@media screen and (max-width: 600px) {
  .App-header {
    background-size: contain; /* Das Bild wird kleiner, um vollständig sichtbar zu sein */
    min-height: 100vh;
    background-repeat: inherit;
  }
}


.App-header p {
  margin-top: -10px;
  font-size: 56px;
  text-shadow: 1px 1px rgb(255, 255, 255);
}

.App-header span {
  margin-top: -70px;
  margin-bottom: 50px;
  font-size: 36px;
  color: rgba(255, 255, 255, 0.5);
  /* text-shadow: 1px 1px rgb(255, 255, 255); */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotatey(0deg);
  }
  to {
    transform: rotatey(360deg);
  }
}

.homeContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.homeContent img{
  margin-top: 20px;
}

.innovation-text {
  font-size: 4em!important;
  margin-top: -10px;
  text-shadow: 2px 2px 2px rgb(0, 0, 0)!important;
}

.innovation-subtext {
  font-size: 3em!important;
  margin-top: -70px;
  z-index: 2;
  text-shadow: 2.5px 2.5px 5.5px rgb(0, 0, 0);
  animation: flyIn 1.5s ease forwards!important;
  color: rgba(255, 255, 255, 0.5)!important;
  
}


.btnContact {
  margin-top: 60px;
  padding: 20px 20px;
  border-radius: 8px;
  background-color: #282c34;
  border: 1px solid white;
  color: white;
  animation: flyIn 1.5s ease forwards;
  cursor: pointer;
}

.btnContact:hover {
  background-color: rgb(255, 83, 30);
  transform: scale(1.04)!important;
}



/*About section*/

.aboutus {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column; /* Ändere die Richtung auf column */
  font-size: 1em;
  padding-left: 100px;
  padding-right: 100px;
  max-width: 800px!important;
  position: relative;
  min-height: 800px;
}

.aboutus h1 {
  margin-top: 60px!important;
  margin-bottom: 10px;
  text-shadow: 2px 5px 2px rgb(10, 10, 10);
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgba(78, 64, 64, 0.733);
  animation: flyIn 1.5s ease forwards;
  border: 1px solid #ddd;
  padding: 10px;
  z-index: 10;
  margin-bottom: 20px;
}

.aboutus p {
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 10;
}

.aboutus p:nth-child(even) {
  left: -50%; /* Absätze mit geraden Index links positionieren */
  padding: 20px;
  background-color: rgba(51, 44, 44, 0.733);
  border: 1px solid #ddd;
  animation: flyRight 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  margin-top: -5px;
}

.aboutus p:nth-child(odd) {
  right: -50%; /* Absätze mit ungeradem Index rechts positionieren */
  padding: 20px;
  border: 1px solid #ddd;
  animation: flyLeft 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgba(78, 64, 64, 0.733);
  margin-top: -5px;
}

.aboutus p:nth-child(even):hover {
  transform: scale(1.2);
}



.aboutus.show p {
  opacity: 1;
}

.Applogo {
position: absolute;
width: 34em;
opacity: 0.3;
top: 950px;
left: 450px;
z-index: 5;
}

.mitchContainer {
  width: 20em;
  position: absolute;
  left: 100px;
  top: 1100px;
  animation: flyLeft 1.5s ease forwards;
}


.mitch {
  width: 18em;
  border: 1px solid #ff5100;
  animation: flyLeft 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgba(78, 64, 64, 0.733);
  margin-bottom: 10px;
}

.mitchContainer span {
  width: 20em;
  border: 1px solid #ddd;
  animation: flyLeft 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgba(78, 64, 64, 0.733);
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.skillContainer {
  display: flex;
  width: 20em;
  height: 20em;
  flex-wrap: wrap;
  margin-left: 10px;
}

.skillContainer div {
  flex-direction: column;
}

.skillContainer span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5em;
  margin-left: 5px;
  margin-right: 5px;
  border: none;
  background: none;
  box-shadow: none;
}

.janisContainer {
  width: 20em;
  position: absolute;
  right: 100px;
  top: 1100px;
  animation: flyRighthart 1.5s ease forwards;
}


.janis {
  width: 18em;
  border: 1px solid #ff7b00;
  animation: flyRighthart 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgba(78, 64, 64, 0.733);
  margin-bottom: 10px;
}

.janisContainer span {
  width: 20em;
  border: 1px solid #ddd;
  animation: flyRighthart 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgba(78, 64, 64, 0.733);
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
}

.skillContainer2 {
  display: flex;
  width: 20em;
  height: 20em;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-left: 10px;
}

.skillContainer2 div {
  flex-direction: column;
}

.skillContainer2 span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5em;
  margin-left: 5px;
  margin-right: 5px;
  border: none;
  background: none;
  box-shadow: none;
}


.skillIcons {
  width: 60px;
  height: 60px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: orangered;
}

.skillIcons:hover {
  transform: scale(1.08);
  background-color: rgb(115, 255, 0);
}


.mockupMaster {
  display: flex!important;
  justify-content: center!important;
  align-items: center!important;
  flex-direction: row!important;
}


.mockupGala {
  width: 75em;
  height: 40em;
  z-index: 20;
}

.mockupFavycon {
  width: 75em;
  height: 40em;
  z-index: 51;
}

.mockupFinanz {
  width: 75em;
  height: 40em;
  z-index: 21;
}

.mockupImmo {
   width: 75em;
  height: 40em;
  z-index: 21;
}

.mockupPodologie{
  width: 75em;
  height: 40em;
  z-index: 21;
}

/* Stile für das Karussell */
.carousel {
  position: relative;
  transition: transform 1.5s ease;
}

.slides {
  display: flex;
  transition: transform 1.5s ease;
}

.slides img {
  width: 75em;
  height: 40em;
  object-fit: cover;
  transition: transform 1.5s ease, opacity 1.5s ease; /* Ändere die Übergangsanimation */
  opacity: 0; /* Anfangs unsichtbar machen */
}

.slides img.active {
  z-index: 40;
  opacity: 1; /* Sichtbar machen, wenn aktiv */
}
.slides img.slide-in-left {
  transform: translateX(-100%);
}

.slides img.slide-in-right {
  transform: translateX(100%);
}

.slides img.active.slide-in-left {
  transform: translateX(0%);
}

.slides img.active.slide-in-right {
  transform: translateX(0%);
}


/* Pfeile für das Karussell */
.prev, .next {
  position: fixed;
  top: 50%;
  transform: translateY(50%);
  background: transparent;
  border: none;
  font-size: 54px;
  cursor: pointer;
  z-index: 160;
  color: antiquewhite;
}

.prev {
  top: 190px;
  left: 2600px;
  z-index: 160;
}

.next {
  top: 190px;
  right:2600px;
  z-index: 160;
}

/*Output*/

.outputContainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: row;

}

.column {
  display: flex;
  flex-direction: column;
  animation: moveLetters 16s infinite linear;
  border-radius: 4px;
}

.column :first-child {
  animation: moveLetters 8s infinite linear 1s;
}
.column .firstSecond {
  animation: moveLetters 8s infinite linear 2s;
}
.column .firstThird {
  animation: moveLetters 8s infinite linear 3s;
}
.column .firstForth {
  animation: moveLetters 8s infinite linear 4s;
}
.column .firstFifth {
  animation: moveLetters 8s infinite linear 5s;
}
.column .firstSixt {
  animation: moveLetters 8s infinite linear 6s;
}
.column .firstSeventh {
  animation: moveLetters 8s infinite linear 7s;
}
.column .firstEight {
  animation: moveLetters 8s infinite linear 8s;
}

@keyframes moveLetters {
  0% {
    transform: translateX(-50%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.outputContainer span {
  position: relative;
  top: 0px;
  font-size: 4em;
  border: 1px solid #ddd;
  animation: flyLeft 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: gradient rgba(27, 19, 19, 0.733);
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  color: orangered;
  border-radius: 4px;
}

.outputSkills {
  height: 40em;
  width: 80em;
  display: flex;
  align-items: center;
  justify-content: space-between;


}

.outputLeft {
  z-index: 10;
  color: white;
  animation: flyRight 1.5s ease forwards;
  display: flex;
}

.uxui {
  width: 20em;
  border: 1px solid #ddd;
  animation: flyLeft 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgb(0, 0, 0);
  padding-left: 5px;
  padding-right: 5px;
  animation: flyRight 1.5s ease forwards;
  border-radius: 4px;
}

.uxui:hover {
  transform: scale(1.04)!important;
  background-color: #282c34;
  z-index: 20;
  border-color: orangered;
}

.contact h3 {
  color: rgb(255, 255, 255);
}

.contact p {
  margin-top: -10px;
}

.angular {
  width: 20em;
  border: 1px solid #ddd;
  animation: flyLeft 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgb(0, 0, 0);
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
  animation: flyRight 1.5s ease forwards;
  border-radius: 4px;
}

.angular:hover {
  transform: scale(1.04)!important;
  background-color: #282c34;
  z-index: 20;
  border-color: orangered;
}

.angular:hover h3 {
  color: orangered;
}

.php {
  width: 20em;
  border: 1px solid #ddd;
  animation: flyLeft 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgb(0, 0, 0);
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
  animation: flyRight 1.5s ease forwards;
  border-radius: 4px;
}

.php:hover {
  transform: scale(1.04)!important;
  background-color: #282c34;
  z-index: 20;
  border-color: orangered;
}

.php:hover h3 {
  color: orangered;
}

.database {
  width: 20em;
  border: 1px solid #ddd;
  animation: flyLeft 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgb(0, 0, 0);
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
  animation: flyRight 1.5s ease forwards;
  border-radius: 4px;
}

.database:hover {
  transform: scale(1.04)!important;
  background-color: #282c34;
  z-index: 20;
  border-color: orangered;
}

.database:hover h3 {
  color: orangered;
}

.react {
  width: 20em;
  border: 1px solid #ddd;
  animation: flyLeft 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgb(0, 0, 0);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 4px;
  animation: flyLeft 1.5s ease forwards;
  border-radius: 4px;
}

.react:hover {
  transform: scale(1.04)!important;
  background-color: #282c34;
  z-index: 20;
  border-color: orangered;
}

.react:hover h3 {
  color: orangered;
}

.javascript {
  width: 20em;
  border: 1px solid #ddd;
  animation: flyLeft 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgb(0, 0, 0);
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
  animation: flyLeft 1.5s ease forwards;
  border-radius: 4px;
}

.javascript:hover {
  transform: scale(1.04)!important;
  background-color: #282c34;
  z-index: 20;
  border-color: orangered;
}

.javascript:hover h3 {
  color: orangered;
}

.sap {
  width: 20em;
  border: 1px solid #ddd;
  animation: flyLeft 1.5s ease forwards;
  box-shadow: 2px 5px 2px rgb(10, 10, 10);
  background-color: rgb(3, 3, 3);
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 20px;
  animation: flyLeft 1.5s ease forwards;
  border-radius: 4px;
}

.sap:hover {
  transform: scale(1.04)!important;
  background-color: #282c34;
  z-index: 20;
  border-color: orangered;
}

.sap:hover h3 {
  color: orangered;
}

.outputRight {
  z-index: 10;
  animation: flyLeft 1.5s ease forwards;
  flex-direction: row;
  display: flex;
}

.hide {
  display: none;
}

/* .show {
  display: flex;
  margin-top: -30px;
} */

.arrow {
  transition: transform 0.3s ease; /* Übergangseffekt für die Rotation */
}

.expanded .arrow {
  transform: rotate(180deg); /* Pfeil um 180 Grad drehen, wenn Abschnitt ausgeklappt ist */
}


.outputContainer {
  display: flex;
  flex-direction: row; /* Elemente nebeneinander positionieren */
}

.outputLeft,
.outputRight {
  flex: 1; /* Jedes Element nimmt gleich viel Platz ein */
}


/*KONTAKT2*/

.hand {
  margin-right: -100px!important;
  margin-top: 150px;
  animation: flyRight 1.5s ease forwards;
  z-index: 5;
}

.instagram-icon {
  position: relative;
  top: 80px;
  left: 150px;
  z-index: 20;
  width: 90px;
  height: 90px;
  cursor: pointer;
}

.instagram-icon:hover {
  transform: scale(1.18);
  border-radius: 24px;
}

.emailIcon {
  position: relative;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 80px;
  z-index: 20;
  cursor: pointer;
}

.emailIcon:hover {
  transform: scale(1.18);
  border-radius: 24px;
}


.emailIcon2 {
  position: relative;
  top: 0px;
  left: 0px;
  width: 80px;
  height: 20px!important;
  z-index: 20;
  cursor: pointer;
  opacity: 0!important;
}

.emailIcon2:hover {
  transform: scale(1.18);
  border-radius: 24px;
  opacity: 0;
}




.phoneIcon {
  position: relative;
  top: 80px;
  left: -150px;
  width: 80px;
  height: 80px;
  z-index: 30;
  cursor: pointer;
}

.phoneIcon:hover {
  transform: scale(1.18);
  border-radius: 24px;
}

.slideIn {
  animation: slideInAnimation 1s forwards;
}

@keyframes slideInAnimation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.slideBtn {
  z-index: 40;
  width: 20px;
  height: 600px;
  position: relative;
  top: 25px;
  left: -1730px;
  background-color: #ff7b005b;
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  font-size: 34;
  font-weight: 600;
  cursor: pointer;
}

.slideBtn:hover {
  background-color: #ff51008f;
  color: #000;
  transform: scale(1.04);
}

.slideBtn2 {
  z-index: 40;
  width: 20px;
  height: 600px;
  position: relative;
  top: 25px;
  left: -1730px;
  background-color: #ff7b005b;
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  font-size: 34;
  font-weight: 600;
  cursor: pointer;
}

.slideBtn2:hover {
  background-color: #ff51008f;
  color: #000;
  transform: scale(1.04);
}

.imageRight {
  color: white!important;
  font-weight: 800;
}

.secondPage {
  top: -752px;
  left: 180px;
  background-color: #222222;
  width: 645px;
  height: 220px;
  /* border-radius: 24px; */
  z-index: 10;
  position: relative;
  background: linear-gradient(to right, #838282fa, #222222);
}

.btnMobile {
  width: 80px;
  height: 40px;
  background-color: transparent;
  position: relative;
  top: -750px;
  left:-175px;
  /* border: none; */
  border-radius: 12px;
  cursor: pointer;
}

.btnMobile:hover {
  transform: scale(1.04);
  border: 1px solid rgb(10, 243, 10);
}


.btnMobile2 {
  width: 80px;
  height: 40px;
  background-color: transparent;
  position: relative;
  top: -750px;
  left:-170px;
  /* border: none; */
  border-radius: 12px;
  cursor: pointer;
}

.btnMobile2:hover {
  transform: scale(1.04);
  border: 1px solid rgb(10, 243, 10);
}

.dominik {
  position: relative;
  top: -270px;
  height: 500px;
  width: 500px;
}

.contactUs {
  height: 120px;
  border-radius: 24px;
  background-color: transparent;
  border: 4px dotted #ff7b00;
  color: #ffffff;
  margin-top: 30px!important;
  font-size: 2em;
  padding-left: 40px;
  padding-right: 40px;
  cursor: pointer;
}

.contactUs:hover {
  transform: scale(1.08);
}

.contactIcons {
  top: -582px;
  left: 180px;
  background-color: #222222;
  width: 645px;
  height: 200px;
  /* border-radius: 24px; */
  z-index: 10;
  position: relative;
  background: linear-gradient(to right, #838282fa, #222222);
}


/*Impressum*/


#impressum {
  font-family: Arial, sans-serif;
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
  background-color: transparent;
  /* border: 1px solid #ddd; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 150px;
}

.impressumLeft {
display: flex;
justify-content: center;
align-items: center;
height: 650px;
width: 600px;
flex-direction: column;
margin-left: -1200px;
margin: 0 auto;
padding: 20px;
background-color: transparent;
border: 1px solid #ddd;
animation: flyLeft 1.5s ease forwards;
}

.impressumRight {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto; /* Ändern Sie die Höhe auf 'auto', um sich an den Inhalt anzupassen */
  width: 1500px;
  max-height: 850px;
  flex-direction: column;
  margin-left: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: transparent;
  border: 1px solid #ddd;
  margin-top: 50px;
  overflow: auto; /* Hinzugefügte overflow-Eigenschaft */
  animation: flyRighthart 1.5s ease forwards;
}


#impressum h2 {
  font-size: 24px;
  margin-bottom: 5px;
  color: orangered;
}

#impressum h3 {
  font-size: 18px;
  margin-bottom: 0px;
  color: rgb(245, 149, 114);
}
#impressum h4 {
  font-size: 18px;
  margin-bottom: -15px;
  color: orangered;
}

#impressum p {
  margin-bottom: 0px;
}

#impressum a {
  color: blue;
  text-decoration: none;
}

#impressum a:hover {
  text-decoration: underline;
}





/*Footer*/

footer {
  width: 100vw;
  height: 200px;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('./img/backgroundbig.png');
  background-repeat: no-repeat;
  background-size: cover; /* Hintergrundbild an die Größe des Elements anpassen */
  background-position: center; /* Zentrieren des Hintergrundbildes */
}

.masterFooter {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 150px;
  margin-bottom: -150px;
}

.fooerColumn {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.imageContainerFooter {
  flex-direction: column;
}

.spanContainerFooter {
  display: flex;
}

.App-logo3 {
  width: 100px;
  margin-bottom: 100px;
}

.logoText {
  font-size: 0.7em;
  z-index: 10;
  margin-top: -115px;
}

.datenschutz {
  font-size: 0.7em;
}

.datenschutz:hover {
  color: #ff7b00;
}

.copyrightContainer {
  font-size: 0.7em;
  color: #ff5100;
}